import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAppState } from '../../state';
import { useTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core/styles/createTheme';
import Button from '@material-ui/core/Button';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '600px',
    minHeight: '300px',
  },
  paper: {
    background: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
  },
  title: {
    textAlign: 'center',
    width: 'calc(100% - 35px)',
    paddingBottom: '1em',
  },
  copyContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingBottom: '1em',
  },
  inputContainer: {
    width: '100%',
    paddingBottom: '10px',
  },
  input: {
    fontFamily: 'inherit',
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.7)',
    color: '#000000',
    textAlign: 'center',
    border: 'none',
    borderRadius: '5px',
    fontSize: '18px',
    padding: '18.5px 14px',
    outline: 'none',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  copyButton: {
    width: 'calc(50% - 5px)',
    background: '#00C6FF',
    color: '#FFFFFF',
    fontSize: '18px',
    '&:hover': {
      background: '#00C6FF',
    },
  },
  leaveButton: {
    width: 'calc(50% - 5px)',
    fontSize: '18px',
  },
});

export default function CopyLinkPopup() {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const roomLink = window.location.hostname + window.location.pathname;
  const { user } = useAppState();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [copyButtonState, setcopyButtonState] = useState<string>(t('ROOM.INVITE.COPY_LINK'));
  const copyTextRef = useRef<HTMLInputElement>(null);

  const copyToClipboard = () => {
    if (null !== copyTextRef.current) {
      copyTextRef.current.select();
      document.execCommand('copy');
      setcopyButtonState(t('ROOM.INVITE.COPIED_LINK'));
      window.analytics.track('Room link copied', {
        link: roomLink,
        userid: user?.id,
        usertype: user?.id ? 'expert' : 'client',
      });
      setTimeout(() => {
        setcopyButtonState(t('ROOM.INVITE.COPY_LINK'));
      }, 2000);
    }
  };

  if (isMobile) return null; // Don't render this component on a mobile device

  return (
    <Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ classes: { root: classes.paper } }}>
      <DialogContent className={classes.container}>
        <Typography className={classes.title} variant="h4">
          {t('ROOM.INVITE.TITLE')}
        </Typography>
        <div className={classes.copyContainer}>
          <div className={classes.inputContainer}>
            <input type="url" ref={copyTextRef} className={classes.input} readOnly defaultValue={roomLink} />
          </div>
          <div className={classes.buttonContainer}>
            {document.queryCommandSupported('copy') && (
              <Button
                onClick={copyToClipboard}
                className={classes.copyButton}
                variant="contained"
                type="button"
                color="primary"
              >
                {copyButtonState}
              </Button>
            )}
            <EndCallButton className={classes.leaveButton} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
