import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../assets/locales/en/translations.json';
import de from '../assets/locales/de/translations.json';
// translations
const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
} as const;

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
