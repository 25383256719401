import { useCallback, useEffect, useState } from 'react';
import * as Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';

export default function useExpertAuth() {
  const [isScheduled, setIsScheduled] = useState(false);
  const [user, setUser] = useState<null | {
    id: number | undefined;
    displayName: string;
    isExpert: boolean;
    photoURL: string | undefined;
  }>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const headers = new window.Headers();
    headers.set('application_hash_public', process.env.REACT_APP_APPLICATION_HASH_PUBLIC as string);
    const params = new window.URLSearchParams({
      session_hash: Cookies.get(process.env.REACT_APP_EXPERT_SESSION_HASH_COOKIE_NAME as string) as string,
    });
    const endpoint = ((process.env.REACT_APP_API_URL as string) + process.env.REACT_APP_AUTH_EXPERT_ENDPOINT) as string;
    fetch(`${endpoint}?${params}`, { headers })
      .then(res => res.json())
      .then((res: { id: number; first_name: string; avatar: string }) => {
        setUser({
          id: res.id,
          displayName: res.first_name as string,
          isExpert: true,
          photoURL: res.avatar || undefined,
        });
        setIsAuthReady(true);
      });
    setIsScheduled(location.pathname.split('/')[1] === 's');
  }, [location]);

  const getToken = useCallback(
    async (identity: string, slug: string, room: string) => {
      const headers = new window.Headers();
      headers.set('application_hash_public', process.env.REACT_APP_APPLICATION_HASH_PUBLIC as string);
      const params = new window.URLSearchParams({
        identity,
        slug,
        session_hash: Cookies.get(process.env.REACT_APP_EXPERT_SESSION_HASH_COOKIE_NAME as string) as string,
      });
      isScheduled ? params.append('appointment_id', room) : params.append('roomName', room);
      const endpointPath = isScheduled
        ? process.env.REACT_APP_TOKEN_EXPERT_SCHEDULED_ENDPOINT
        : process.env.REACT_APP_TOKEN_EXPERT_ENDPOINT;
      const endpoint = ((process.env.REACT_APP_API_URL as string) + endpointPath) as string;

      return fetch(`${endpoint}?${params}`, { headers }).then(res => res.json());
    },
    [isScheduled]
  );

  const getTestTokens = useCallback(async (publisher: string, subscriber: string, roomName: string) => {
    const headers = new window.Headers();
    headers.set('application_hash_public', process.env.REACT_APP_APPLICATION_HASH_PUBLIC as string);
    const endpoint = ((process.env.REACT_APP_API_URL as string) + process.env.REACT_APP_TEST_TOKEN_ENDPOINT) as string;
    const params = new window.URLSearchParams({ publisher, subscriber, roomName });

    return fetch(`${endpoint}?${params}`, { headers }).then(res => res.json());
  }, []);

  return { isScheduled, user, isAuthReady, getToken, getTestTokens };
}
