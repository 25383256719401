import React from 'react';
import EndContainer from '../EndContainer/EndContainer';
import { Typography, Button, Link, List, ListItemIcon, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppState } from '../../state';
import { useTranslation } from 'react-i18next';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles({
  list: {
    display: 'table',
    margin: '0 auto',
  },
  listText: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  listIconItem: {
    minWidth: '40px',
  },
  listIcon: {
    height: '32px',
    width: '32px',
    color: '#F2CD00',
  },
  ctaContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& form': {
      margin: '1em auto',
    },
    '& a': {
      fontSize: '1rem',
      textAlign: 'center',
    },
  },
  ctaButton: {
    display: 'flex',
    margin: '1.5em auto 0',
  },
});

export default function EndScreens() {
  const classes = useStyles();
  const { user, isCustomDomain } = useAppState();
  const { t } = useTranslation();

  return (
    <EndContainer>
      <Typography
        align="center"
        variant="subtitle1"
        paragraph
        dangerouslySetInnerHTML={{ __html: t('END.RETURN_TO_CALL') }}
      ></Typography>
      <Typography align="center" variant="h5">
        {t('END.TITLE')}
      </Typography>
      {!(isCustomDomain || user?.isExpert) ? (
        <>
          <Typography align="center" variant="h5" paragraph>
            {t('END.CUSTOM_DOMAIN.TITLE')}
          </Typography>
          <Typography align="center" variant="subtitle1">
            {t('END.CUSTOM_DOMAIN.SUBTITLE')}
          </Typography>
          <Typography align="center" variant="subtitle1" paragraph>
            {t('END.CUSTOM_DOMAIN.SUBTITLE_2')}
          </Typography>
          <List
            classes={{
              root: classes.list,
            }}
          >
            <ListItem>
              <ListItemIcon
                classes={{
                  root: classes.listIconItem,
                }}
              >
                <StarIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.listText,
                }}
                primary={t('END.CUSTOM_DOMAIN.FEATURE_1')}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon
                classes={{
                  root: classes.listIconItem,
                }}
              >
                <StarIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.listText,
                }}
                primary={t('END.CUSTOM_DOMAIN.FEATURE_2')}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon
                classes={{
                  root: classes.listIconItem,
                }}
              >
                <StarIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.listText,
                }}
                primary={t('END.CUSTOM_DOMAIN.FEATURE_3')}
              />
            </ListItem>
          </List>
          <div className={classes.ctaContainer}>
            <form action={t('END.CUSTOM_DOMAIN.BUTTON_LINK')}>
              <input type="hidden" name="utm_campaign" value="loop" />
              <input type="hidden" name="utm_medium" value="form" />
              <input type="hidden" name="utm_source" value="video_call" />
              <Button type="submit" variant="contained" color="primary">
                {t('END.CUSTOM_DOMAIN.BUTTON')}
              </Button>
            </form>
            <Link href={t('END.CUSTOM_DOMAIN.BUTTON_SECONDARY_LINK')} target="_blank" rel="noopener noreferrer">
              {t('END.CUSTOM_DOMAIN.BUTTON_SECONDARY')}
            </Link>
          </div>
        </>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.ctaButton}
          onClick={() => window.location.reload()}
        >
          {t('END.BUTTON')}
        </Button>
      )}
    </EndContainer>
  );
}
