import { useEffect, useState } from 'react';

export default function useCustomDomain() {
  const [isCustomDomain, setIsCustomDomain] = useState(false);

  useEffect(() => {
    setIsCustomDomain(window.location.hostname !== process.env.REACT_APP_HOSTNAME);
    if (isCustomDomain) {
      const favicon = document.getElementById('favicon') as HTMLLinkElement;
      if (favicon) {
        favicon.href = document.createElement('canvas').toDataURL('image/x-icon'); // Generate transparent favicon
      }
    }
  }, [isCustomDomain]);

  return { isCustomDomain };
}
