import React, { SVGProps } from 'react';

export default function MeetFoxLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="110" height="30" viewBox="0 0 3800 1000" {...props}>
      <path
        d=" M 2260 318.5 L 2260 637 L 2324.5 637 L 2389 637 L 2389 504.5 L 2389 372 L 2476.5 372 L 2564 372 L 2564 322.5 L 2564 273 L 2476.5 273 L 2389 273 L 2389 186 L 2389 99 L 2492.5 99 L 2596 99 L 2596 49.5 L 2596 0 L 2428 0 L 2260 0 L 2260 318.5 Z"
        fill="#00c6ff"
      />
      <path
        d=" M 0 181 L 326.5 507.5 C 458.566681 367.933990 524.733337 298.151001 525 298.151001 C 525.266663 298.151001 525.266663 393.433990 525 584 C 393.066681 716.333313 326.899994 782.5 326.5 782.5 C 326.100006 782.5 259.933319 716.333313 128 584 L 128 380.5 L 0 380.5 L 0 637 C 212.5 854.533325 318.916656 963.366638 319.250000 963.5 C 319.583344 963.633362 430.833344 854.799988 653 637 L 653 0 L 326.5 326.5 L 0 0 L 0 181 Z"
        fill="#00c6ff"
      />
      <path
        d=" M 1902 105 L 1902 163 L 1867.5 163 L 1833 163 L 1833 208 L 1833 253 L 1867.400024 253 L 1901.900024 253 L 1902.300049 386.200012 C 1902.699951 531.799988 1902.5 528 1908.599976 552.799988 C 1920.5 601.299988 1950.199951 630.700012 1998.199951 641.400024 C 2032.099976 648.900024 2071.300049 646.799988 2108.800049 635.400024 C 2116.399902 633.099976 2117 632.799988 2117 630.200012 C 2117 628.700012 2114.699951 607.900024 2112 584 C 2109.300049 560.099976 2107 540.200012 2107 539.900024 C 2107 539.599976 2103.5 540.200012 2099.300049 541.200012 C 2094.600098 542.400024 2086.300049 543.400024 2078.300049 543.700012 C 2066.800049 544.200012 2064.399902 544 2058.300049 542.099976 C 2041.099976 536.799988 2033.099976 524.900024 2031 501.5 C 2030.5 496 2030.099976 437.799988 2030 372.200012 L 2030 253 L 2070 253 L 2110 253 L 2110 208 L 2110 163 L 2070 163 L 2030 163 L 2030 105 L 2030 47 L 1966 47 L 1902 47 L 1902 105 Z"
        fill="#00c6ff"
      />
      <path
        d=" M 991.5 154.699997 C 932.799988 158.699997 884 183.199997 849.599976 226 C 822.5 259.700012 806.599976 297.600006 798.799988 347 C 796.799988 360.299988 796.599976 364.899994 796.5 403 C 796.5 437.399994 796.799988 446.399994 798.200012 455.700012 C 806.599976 510.600006 827.099976 553.200012 861.700012 587.700012 C 892.799988 618.599976 932 636.700012 983 643.599976 C 1000.299988 646 1041.800049 646 1062.5 643.599976 C 1091.699951 640.299988 1119.199951 633.599976 1144.900024 623.599976 C 1166.900024 615 1190.800049 602.200012 1200.300049 593.799988 L 1204.099976 590.5 L 1186.900024 551.5 C 1177.5 530 1169.599976 512.299988 1169.400024 512.099976 C 1169.199951 511.799988 1161.400024 515.299988 1152.199951 519.799988 C 1116.800049 537 1094.099976 543.400024 1059 546 C 1017.400024 549.099976 989.700012 543.700012 968.900024 528.400024 C 952.400024 516.299988 937.599976 492.600006 931.5 468.200012 C 928.799988 457.200012 926.5 440.899994 927.299988 438.5 C 927.900024 437.200012 942.599976 437 1069 437 L 1210 437 L 1210 396.600006 C 1210 354 1209.199951 338.200012 1206 318.100006 C 1191.699951 229.800003 1138.199951 171.699997 1058.400024 158 C 1038.599976 154.600006 1012.099976 153.300003 991.5 154.699997 Z M 1032 255.699997 C 1056 260.5 1072.099976 277.299988 1079.400024 305 C 1082.400024 316.200012 1084 329 1084 341.700012 L 1084 351 L 1006.5 351 L 929 351 L 929 347.399994 C 929 341.799988 933.700012 320.100006 936.599976 311.799988 C 942.299988 296 950.5 282.399994 960 273.100006 C 977.099976 256.399994 1003.700012 249.899994 1032 255.699997 Z"
        fill="#00c6ff"
      />
      <path
        d=" M 1518 155 C 1459.900024 160.5 1414.099976 184 1380.900024 225.300003 C 1351.5 261.700012 1335 302.700012 1328.300049 356.100006 C 1325.900024 375.399994 1325.900024 433.200012 1328.300049 451.299988 C 1333.300049 488.899994 1344.300049 521.200012 1361.199951 548.5 C 1395.099976 603.099976 1445.5 634.599976 1513.099976 643.400024 C 1547.900024 648 1593.400024 645.599976 1631 637.400024 C 1646.5 633.900024 1675.099976 624.299988 1690.599976 617.299988 C 1708.800049 609.200012 1736 592.400024 1734.599976 590.200012 C 1734.400024 589.799988 1726.699951 572.400024 1717.5 551.5 C 1708.400024 530.599976 1700.5 513.099976 1700 512.599976 C 1699.400024 512.099976 1693.900024 514.400024 1686.300049 518.200012 C 1669.099976 527.099976 1647.800049 535.400024 1631.699951 539.5 C 1611.5 544.599976 1596.199951 546.299988 1569.5 546.400024 C 1543.800049 546.400024 1535 545.200012 1519.300049 539.099976 C 1488.199951 527 1466.699951 497 1459.5 455.600006 C 1458.699951 450.700012 1458 444.5 1458 441.899994 L 1458 437 L 1599.599976 437 L 1741.300049 437 L 1740.699951 390.200012 C 1740.300049 356.700012 1739.699951 340 1738.599976 331.100006 C 1731.5 275.399994 1712.099976 233.199997 1678.800049 201.5 C 1652.699951 176.5 1618.5 161.399994 1576 156 C 1564.400024 154.5 1529.599976 153.899994 1518 155 Z M 1565 256.200012 C 1584.5 261 1597.699951 272.799988 1605.900024 292.5 C 1611.400024 305.700012 1615 326.299988 1615 345.200012 L 1615 351 L 1537.599976 351 C 1492.5 351 1459.900024 350.600006 1459.599976 350.100006 C 1459.300049 349.600006 1459.900024 344.100006 1461 337.799988 C 1470.900024 279.899994 1501.199951 251 1549 253.800003 C 1553.699951 254.100006 1560.900024 255.100006 1565 256.200012 Z"
        fill="#00c6ff"
      />
      <path
        d=" M 2867.5 155 C 2836 157.800003 2809.100098 165.199997 2784 178.100006 C 2767 186.899994 2754.600098 195.699997 2740.699951 208.899994 C 2701.100098 246.899994 2678.100098 296.700012 2670.899902 360 C 2668.899902 377 2669.199951 426.899994 2671.300049 443.899994 C 2675.600098 477.899994 2683.199951 504.399994 2696.199951 530.5 C 2720.100098 578.599976 2758.199951 613.799988 2805.100098 631.299988 C 2857.899902 651.099976 2932.399902 650.5 2984.300049 629.900024 C 3024.899902 613.799988 3057.800049 585 3081.899902 544.5 C 3097.800049 517.799988 3108 487.399994 3113.699951 449.5 C 3115.199951 439.5 3115.5 431.200012 3115.5 399.5 C 3115.399902 365.100006 3115.199951 360.200012 3113.199951 347.100006 C 3107.100098 308.600006 3094.699951 274.799988 3076.300049 247 C 3041.600098 194.5 2993.699951 165 2929.5 156.5 C 2915.199951 154.600006 2881.5 153.800003 2867.5 155 Z M 2912.5 255.600006 C 2946.300049 262 2968.800049 287.799988 2980.5 333.5 C 2990.399902 372.200012 2990.399902 428.899994 2980.399902 468.399994 C 2973 498 2958.600098 521.799988 2941.199951 533.299988 C 2926.5 543.099976 2913.300049 546.599976 2891.5 546.400024 C 2873.699951 546.299988 2865.399902 544.400024 2851.399902 537.5 C 2828.899902 526.5 2813.399902 503.5 2804.600098 468.200012 C 2796.5 435.700012 2795 385.899994 2801.100098 349.899994 C 2805.100098 326 2814.899902 300.399994 2825.5 285.5 C 2843.899902 259.700012 2875.899902 248.699997 2912.5 255.600006 Z"
        fill="#00c6ff"
      />
      <path
        d=" M 3211.899902 164.699997 C 3212.399902 165.699997 3244.5 218.399994 3283.300049 281.799988 L 3353.800049 397.100006 L 3291.800049 497.799988 C 3257.600098 553.200012 3224.899902 606.400024 3218.899902 616 C 3213 625.700012 3207.800049 634.299988 3207.399902 635.299988 C 3206.800049 636.900024 3211 637 3279.399902 636.799988 L 3352.100098 636.5 L 3390.199951 559.200012 C 3411.199951 516.799988 3428.600098 482 3428.899902 482 C 3429.300049 482 3446.699951 516.799988 3467.600098 559.200012 L 3505.600098 636.5 L 3577.899902 636.799988 C 3646.5 637 3650.199951 636.900024 3649.800049 635.299988 C 3649.5 634.299988 3616.600098 580.400024 3576.600098 515.599976 C 3515.199951 416 3504 397.299988 3504.800049 395.600006 C 3505.300049 394.399994 3536.5 343.100006 3574 281.5 C 3611.5 219.899994 3643.100098 168 3644.199951 166.199997 L 3646.199951 163 L 3573.300049 163 L 3500.399902 163 L 3464.399902 237.699997 C 3444.600098 278.799988 3427.899902 312.5 3427.300049 312.700012 C 3426.800049 312.899994 3410.399902 279.299988 3390.899902 238 L 3355.600098 163 L 3283.300049 163 C 3214.699951 163 3211 163.100006 3211.899902 164.699997 Z"
        fill="#00c6ff"
      />
    </svg>
  );
}
