import React, { useState, useRef } from 'react';
import Button from '@material-ui/core/Button';

import DeviceSelectionDialog from '../../../DeviceSelectionDialog/DeviceSelectionDialog';
import SettingsIcon from '../../../../icons/SettingsIcon';

export default function SettingsMenu({ className }: { className?: string }) {
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={() => setDeviceSettingsOpen(true)}
        startIcon={<SettingsIcon />}
        className={className}
      ></Button>
      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false);
        }}
      />
    </>
  );
}
