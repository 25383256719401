import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import io from 'socket.io-client';
const NEW_CHAT_MESSAGE_EVENT = 'newChatMessage'; // Name of the event

export type messageGroup = {
  id: string;
  identity: string;
  ownedByCurrentUser: boolean;
  created: Date;
  body: string[];
};

type ChatContextType = {
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  sendMessage: (messageBody: string) => void;
  hasUnreadMessages: boolean;
  unreadMessagesCount: number;
  messages: messageGroup[];
};

export const ChatContext = createContext<ChatContextType>(null!);

type ChatProviderProps = {
  children: React.ReactNode;
};

export function ChatProvider({ children }: ChatProviderProps) {
  const { room: roomObject } = useVideoContext();
  const room = roomObject?.sid;
  const localParticipant = roomObject?.localParticipant;

  const socketRef = useRef<SocketIOClient.Socket>();
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [messages, setMessages] = useState<messageGroup[]>([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  useEffect(() => {
    if (room) {
      // Creates a WebSocket connection
      socketRef.current = io.connect(process.env.REACT_APP_API_URL as string, {
        transports: ['websocket'],
        query: { room },
      });

      // Listens for incoming messages
      socketRef.current.on(NEW_CHAT_MESSAGE_EVENT, (message: messageGroup) => {
        if (
          messages.length &&
          new Date(messages[messages.length - 1].created).toLocaleTimeString(navigator.language, {
            hour: '2-digit',
            minute: '2-digit',
          }) ===
            new Date(message.created).toLocaleTimeString(navigator.language, {
              hour: '2-digit',
              minute: '2-digit',
            }) &&
          messages[messages.length - 1].identity === message.identity
        ) {
          const newMessages = [...messages];
          newMessages[newMessages.length - 1].body = [
            ...newMessages[newMessages.length - 1].body,
            message.body.toString(),
          ];
          setMessages(newMessages);
        } else {
          const incomingMessage = {
            ...message,
            ownedByCurrentUser: message.id === localParticipant?.identity,
          };
          setMessages([...messages, incomingMessage]);
        }

        if (!isChatWindowOpenRef.current) {
          setUnreadMessagesCount(unreadMessagesCount + 1);
        }
      });

      // Destroys the socket reference
      // when the connection is closed
      return () => {
        socketRef.current?.disconnect();
      };
    }
  }, [room, localParticipant, messages, unreadMessagesCount]);

  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (!isChatWindowOpenRef.current && messages.length) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) {
      setHasUnreadMessages(false);
      setUnreadMessagesCount(0);
    }
  }, [isChatWindowOpen]);

  // Sends a message to the server that
  // forwards it to all users in the same room
  const sendMessage = useCallback(
    (message: string) => {
      socketRef.current?.emit(NEW_CHAT_MESSAGE_EVENT, {
        id: localParticipant?.identity,
        body: [message],
        identity: localParticipant?.identity,
        created: new Date(),
      });
    },
    [localParticipant]
  );

  return (
    <ChatContext.Provider
      value={{ isChatWindowOpen, setIsChatWindowOpen, sendMessage, hasUnreadMessages, unreadMessagesCount, messages }}
    >
      {children}
    </ChatContext.Provider>
  );
}
