import React, { ChangeEvent, FormEvent } from 'react';
// eslint-disable-next-line
import { Typography, makeStyles, TextField, Button, Link, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';
import { setUsername } from '../../../utils/indexedDB/indexedDB';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '2em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '2em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& button': {
        marginTop: '1em',
      },
    },
  },
  textFieldContainer: {
    width: 'calc(70% - 10px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  continueButton: {
    width: 'calc(30% - 10px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomScreenProps {
  name: string;
  slug: string;
  room: string;
  setName: (name: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomScreen({ name, slug, room, setName, handleSubmit }: RoomScreenProps) {
  const classes = useStyles();
  const { user, isCustomDomain } = useAppState();
  const { t } = useTranslation();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    setName(event.target.value);
  };

  return (
    <>
      <Typography variant="h5" align="center" className={classes.gutterBottom}>
        {t('PREVIEW.NAME.TITLE')}
      </Typography>
      <Typography variant="body1">{t('PREVIEW.NAME.INPUT.LABEL')}</Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          {!user?.isExpert && (
            <div className={classes.textFieldContainer}>
              <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={name}
                onChange={handleNameChange}
              />
            </div>
          )}
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!name || !room || !slug}
            className={classes.continueButton}
          >
            {t('PREVIEW.NAME.INPUT.SUBMIT')}
          </Button>
        </div>
      </form>
      {!isCustomDomain && (
        <>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.gutterBottom}
            dangerouslySetInnerHTML={{ __html: t('PREVIEW.NAME.CONSENT') }}
          ></Typography>
          <Typography
            variant="subtitle1"
            align="center"
            dangerouslySetInnerHTML={{ __html: t('PREVIEW.NAME.LOGIN') }}
          ></Typography>
        </>
      )}
    </>
  );
}
