import React from 'react';

export default function AvatarIcon({ identity }: { identity: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 73 73">
      <g fill="none" fillRule="evenodd">
        <circle cx="36.5" cy="36.5" r="33.5" fill="#FFF" stroke="#00c6ff" strokeWidth="3px" />
        <text
          x="50%"
          y="50%"
          fontSize="24"
          textAnchor="middle"
          alignmentBaseline="middle"
          strokeWidth="3px"
          fill="#00c6ff"
        >
          {identity}
        </text>
      </g>
    </svg>
  );
}
