import React from 'react';
import Video from 'twilio-video';
import { Container, Link, Typography, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SupportedDevices from '../../assets/images/SupportedDevices.png';
import { useAppState } from '../../state';

const useStyles = makeStyles({
  container: {
    marginTop: '2.5em',
  },
  paper: {
    padding: '1em',
  },
  heading: {
    marginBottom: '0.4em',
  },
  image: {
    width: '100%',
  },
});

export default function UnsupportedBrowserWarning({ children }: { children: React.ReactElement }) {
  const classes = useStyles();
  const { isCustomDomain } = useAppState();

  if (!Video.isSupported) {
    return (
      <Container>
        <Grid container justifyContent="center" className={classes.container}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Typography variant="h4" className={classes.heading}>
                <br />
                Sorry...
              </Typography>
              <Typography>
                <b>Your browser or device is not compatible to join this call.</b>
                <br />
                Please open the meeting link in one of the supported browsers or on one of the supported devices:
                <br />
                <img src={SupportedDevices} className={classes.image} alt="MeetFox supported devices" />
                {!isCustomDomain && (
                  <>
                    If you need any assistance, feel free to{' '}
                    <Link href="https://help.meetfox.com/en/" target="_blank" rel="noopener noreferrer">
                      <u>contact our support</u>
                    </Link>
                    .
                    <br />
                    We'd be happy to help and want to ensure your meeting is a success!
                  </>
                )}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return children;
}
