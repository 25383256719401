import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AvatarIcon from '../../../../icons/AvatarIcon';
import { messageGroup } from '../../../ChatProvider';
import Messages from './Messages/Messages';

const useStyles = makeStyles({
  messageGroup: {
    display: 'grid',
    padding: '8px 8px 0',
    gridTemplateColumns: '32px 8px [main-column] minmax(0,100%) 8px 32px',
    gridTemplateRows: '[timestamp] auto [name] auto [main-row] auto',
    gridTemplateAreas: '". . . . ." ". . . . ." "avatar . message-area . ."',
  },
  messageGroupSelf: {
    gridTemplateAreas: '". . . . ." ". . . . ." ". . message-area . avatar"',
  },
  timestamp: {
    gridRow: 'timestamp',
    gridColumn: 'main-column',
    display: 'inline-block',
    margin: 'auto',
    fontSize: '12px',
    color: '#0A2540',
  },
  displayName: {
    gridColumn: 'main-column',
    padding: '0 0 4px',
    margin: '0',
    fontSize: '12px',
    color: '#0A2540',
    gridRow: 'name',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
  },
  displayNameSelf: {
    textAlign: 'right',
  },
  avatar: {
    height: '32px',
    width: '32px',
    margin: '0',
    gridArea: 'avatar',
    gridRow: 'main-row',
    alignSelf: 'end',
    '& svg': {
      height: '32px',
      width: '32px',
    },
  },
  content: {
    gridArea: 'message-area',
    gridRow: 'main-row',
    display: 'block',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    '& :not(:last-child)': {
      marginBottom: '2px',
    },
  },
});

export default function MessageGroups({ messageGroups }: { messageGroups: messageGroup[] }) {
  const classes = useStyles();

  return (
    <>
      {messageGroups.map((group, idx) => (
        <div
          key={idx}
          className={clsx(classes.messageGroup, {
            [classes.messageGroupSelf]: group.ownedByCurrentUser,
          })}
        >
          <div className={classes.timestamp}>
            {new Date(group.created).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })}
          </div>
          <div
            className={clsx(classes.displayName, {
              [classes.displayNameSelf]: group.ownedByCurrentUser,
            })}
          >
            {group.identity.substring(4) || 'Guest'}
          </div>
          <div className={classes.avatar}>
            <AvatarIcon identity={group.identity.substring(4, 6) || 'G'} />
          </div>
          <div className={classes.content}>
            <Messages messages={group.body} ownedByCurrentUser={group.ownedByCurrentUser} />
          </div>
        </div>
      ))}
    </>
  );
}
