import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function useLanguage() {
  const [lang, setLang] = useState<string>('en');
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    const headers = new window.Headers();
    headers.set('application_hash_public', process.env.REACT_APP_APPLICATION_HASH_PUBLIC as string);
    const params = new window.URLSearchParams({
      slug: location.pathname.split('/')[2],
    });
    const endpoint = ((process.env.REACT_APP_API_URL as string) +
      process.env.REACT_APP_EXPERT_LANGUAGE_ENDPOINT) as string;
    fetch(`${endpoint}?${params}`, { headers })
      .then(res => res.json())
      .then((res: { lang: string }) => {
        setLang(res.lang);
        i18n.changeLanguage(res.lang);
      });
  }, [location, i18n]);

  return { lang };
}
