import React from 'react';

import Button from '@material-ui/core/Button';
import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useTranslation } from 'react-i18next';

export default function ToggleAudioButton(props: {
  disabled?: boolean;
  className?: string;
  controls?: boolean;
  iconFill?: string;
}) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');
  const { t } = useTranslation();

  return (
    <Button
      className={props.className}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || props.disabled}
      startIcon={isAudioEnabled ? <MicIcon fill={props.iconFill} /> : <MicOffIcon fill={props.iconFill} />}
      data-cy-audio-toggle
    >
      {!hasAudioTrack ? 'No Audio' : props.controls ? t('ROOM.MIC') : ''}
    </Button>
  );
}
