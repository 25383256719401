import React from 'react';
import { Link, Typography, Grid } from '@material-ui/core';
import { Theme, makeStyles, styled } from '@material-ui/core/styles';
import useHeight from './hooks/useHeight/useHeight';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '2em',
  },
  paper: {
    padding: '1em',
  },
  heading: {
    display: 'block',
    margin: '0 20px 0 0',
    padding: '10px 23px 10px 0',
    borderRight: '1px solid rgba(0, 0, 0,.3)',
    [theme.breakpoints.down('xs')]: {
      borderRight: '0px',
      margin: '0',
    },
  },
  link: {
    textDecoration: 'underline',
  },
}));

export default function NotFound() {
  const classes = useStyles();
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  return (
    <Container style={{ height }}>
      <Grid container justifyContent="center" alignItems="center" className={classes.container}>
        <Typography variant="h3" className={classes.heading}>
          404
        </Typography>
        <Typography variant="subtitle1">
          Please check your{' '}
          <Link className={classes.link} href={process.env.REACT_APP_EXPERT_DASHBOARD_ROOMS as string}>
            rooms page
          </Link>
          .
        </Typography>
      </Grid>
    </Container>
  );
}
