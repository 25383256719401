import React from 'react';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import ChatIcon from '../../../icons/ChatIcon';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import withStyles from '@material-ui/core/styles/withStyles';
import { useTranslation } from 'react-i18next';

const UnreadMessagesCountBadge = withStyles({
  badge: {
    background: '#DDE5E9',
    color: '#0A2540',
  },
})(Badge);

export default function ToggleChatButton(props: { className?: string; iconFill?: string }) {
  const { isChatWindowOpen, setIsChatWindowOpen, unreadMessagesCount } = useChatContext();
  const { setIsBackgroundSelectionOpen } = useVideoContext();
  const { t } = useTranslation();

  const toggleChatWindow = () => {
    setIsChatWindowOpen(!isChatWindowOpen);
    setIsBackgroundSelectionOpen(false);
  };

  return (
    <Button
      className={props.className}
      onClick={toggleChatWindow}
      startIcon={
        <UnreadMessagesCountBadge badgeContent={unreadMessagesCount}>
          <ChatIcon fill={props.iconFill} />
        </UnreadMessagesCountBadge>
      }
    >
      {t('ROOM.CHAT')}
    </Button>
  );
}
