import React, { useState, ChangeEvent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SendIcon from '@material-ui/icons/Send';

import useChatContext from '../../../hooks/useChatContext/useChatContext';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: `${theme.footerHeight}px`,
      boxShadow: 'inset 0 1px 0 rgb(0 0 0 / 12%)',
      display: 'flex',
      flex: '0 0 auto',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
      },
    },
    form: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '12px 8px',
    },
    input: {
      flex: '1 1',
      overflow: 'hidden',
      width: '100%',
    },
    button: {
      cursor: 'pointer',
    },
  })
);

export default function ChatWindowFooter() {
  const classes = useStyles();
  const { sendMessage } = useChatContext();
  const { t } = useTranslation();

  const [newMessage, setNewMessage] = useState(''); // Message to be sent
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isValidMessage = /\S/.test(newMessage);

  const handleNewMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNewMessage(event.target.value);
  };

  const handleSendMessage = (message: string) => {
    if (isValidMessage) {
      sendMessage(message.trim());
      setNewMessage('');
    }
  };

  // ensures pressing enter + shift creates a new line, so that enter on its own only sends the message:
  const handleReturnKeyPress = (event: React.KeyboardEvent) => {
    if (!isMobile && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage(newMessage);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.form}>
        <InputBase
          className={classes.input}
          value={newMessage}
          onKeyPress={handleReturnKeyPress}
          onChange={handleNewMessageChange}
          multiline
          maxRows={4}
          placeholder={t('ROOM.CHAT_PLACEHOLDER')}
          inputProps={{ 'aria-label': 'Chat input' }}
          fullWidth
        />
        <SendIcon className={classes.button} onClick={() => handleSendMessage(newMessage)} />
      </div>
    </div>
  );
}
