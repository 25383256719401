import React, { useState, useRef } from 'react';
import { useAppState } from '../../../state';
import { isSupported } from '@twilio/video-processors';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuContainer from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import { Theme, useMediaQuery } from '@material-ui/core';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';

export default function Menu(props: { buttonClassName?: string }) {
  const { isCustomDomain, setIsGalleryViewActive, isGalleryViewActive } = useAppState();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const { setIsChatWindowOpen } = useChatContext();
  const { setIsBackgroundSelectionOpen } = useVideoContext();

  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button
        onClick={() => (isCustomDomain ? setSettingsOpen(isOpen => !isOpen) : setMenuOpen(isOpen => !isOpen))}
        ref={anchorRef}
        className={props.buttonClassName}
      >
        {isMobile ? <MoreIcon style={{ color: '#fff' }} /> : <SettingsIcon style={{ color: '#fff' }} />}
      </Button>
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            window.open(t('ROOM.HELP_LINK'), '_blank', 'noopener,noreferrer');
            setMenuOpen(false);
          }}
        >
          <Typography variant="body1">{t('ROOM.HELP')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => setSettingsOpen(true)}>
          <Typography variant="body1">{t('ROOM.SETTINGS')}</Typography>
        </MenuItem>
        {isSupported && (
          <MenuItem
            onClick={() => {
              setIsBackgroundSelectionOpen(true);
              setIsChatWindowOpen(false);
              setMenuOpen(false);
            }}
          >
            <Typography variant="body1">{t('ROOM.BACKGROUNDS')}</Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            VideoRoomMonitor.toggleMonitor();
            setMenuOpen(false);
          }}
        >
          <Typography variant="body1">{t('ROOM.MONITOR')}</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setIsGalleryViewActive(isGallery => !isGallery);
            setMenuOpen(false);
          }}
        >
          <Typography variant="body1">{isGalleryViewActive ? t('ROOM.SPEAKER') : t('ROOM.GALLERY')}</Typography>
        </MenuItem>
      </MenuContainer>
      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
